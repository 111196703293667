import moment, {MomentInput} from "moment-timezone";
import {Translate} from "next-translate";

export const todayTomorrow2 = (t: Translate, time: MomentInput, lowerCase?: boolean): string =>
  time
    ? moment(time).calendar(null, {
        lastDay: `[${t(lowerCase ? "yesterday" : "Yesterday")}]`,
        sameDay: `[${t(lowerCase ? "today" : "Today")}] h:mma`,
        nextDay: `[${t(lowerCase ? "tomorrow" : "Tomorrow")}] h:mma`,
        lastWeek: `[${t(lowerCase ? "last" : "Last")}] dddd`,
        nextWeek: "ddd h:mma",
        sameElse: "ddd, MMM Do",
      })
    : "";

export const todayTomorrow4 = (t: Translate, time: MomentInput): string =>
  time
    ? moment(time).calendar(null, {
        lastDay: `[${t("yesterday at")}]`,
        sameDay: `[${t("Next today at")}] h:mma`,
        nextDay: `[${t("Next tomorrow at")}] h:mma`,
        lastWeek: `[${t("last ")}] dddd`,
        nextWeek: `[${t("Next on")}] ddd h:mma`,
        sameElse: "ddd, MMM Do h:mma",
      })
    : "";
