import {ApptSlot} from "@services/monolith/availability";
import moment from "moment-timezone";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";

import {loadAvailableSlots} from "../_services/api";
import {ApptSlotBlock} from "../_services/types";
import {laTimezone, millisPerMinute} from "../components/_common/_constants";
import {SpecialtyId} from "../constants/specialtyIds";
import {useTypedSelector} from "../store";
import {RootStateLocation} from "../store/types";
import {LocationForFetchCachedSlot, fetchCachedSlotAround} from "../utils/fetchCachedSlotAround";
import {useUserSelectedLocation} from "../utils/browser-storage/userSelectedLocation";

export const getSoonestSlotFromApptSlotBlock = (slotTimes: ApptSlotBlock[]): number =>
  slotTimes?.[0]?.[0]?.time;

export const useGetSoonestInPracticeBySpecialtyId = ({
  specialtyId,
  locations,
}: {
  specialtyId: SpecialtyId;
  locations: LocationForFetchCachedSlot[];
}): Partial<ApptSlot | null> => {
  const [slot, setSlot] = useState<Partial<ApptSlot>>({});

  useEffect(() => {
    fetchCachedSlotAround({locations, specialtyId}).then(setSlot);
  }, [locations, specialtyId]);

  return slot || null;
};

export type SoonestSlotTimeByLocationAndSpecialty = {
  [locationId: string]: {
    [specialtyId: string]: number;
  };
};

export const useGetSoonestTimeByLocationsAndSpecialtyIds = ({
  locations,
  specialtyIds,
  daysCount = 21,
  specialtyCount = 1, // if specialtyIds is not set
  locationCount = 3, // if locations are not set, closest locations count
}: {
  locations?: RootStateLocation[];
  specialtyIds?: SpecialtyId[];
  daysCount?: number;
  specialtyCount?: number;
  locationCount?: number;
}): SoonestSlotTimeByLocationAndSpecialty => {
  const {locationsSorted, locations: allLocations} = useTypedSelector(({config}) => ({...config}));
  const {regionSlug: selectedRegion} = useUserSelectedLocation();
  const router = useRouter();
  const [slotLocationWithTime, setSlotLocationWithTime] =
    useState<SoonestSlotTimeByLocationAndSpecialty>({});

  useEffect(() => {
    if (!locationsSorted) return;

    const now = +moment();
    (locations || allLocations.slice(0, locationCount))
      .map(l => {
        const sortedSpecialties = l.specialties.vals().sortBy("sortIndex").slice(0, specialtyCount);
        return (specialtyIds || sortedSpecialties.map(s => s.id)) // if specialIds param is null, try with first specialtyId
          .map(sId => {
            const {defaultNewPatientApptDuration: duration = 10, apptLeadTime = 0} =
              l.specialties[sId] || {};

            const from = (apptLeadTime * millisPerMinute || 0) < 0 ? now : now + apptLeadTime; // check if from is in past

            return loadAvailableSlots({
              practiceId: l.practiceId,
              locationId: l.id,
              from,
              to: +moment(now).add(daysCount, "days").endOf("day"),
              duration,
              specialtyId: sId,
              timezone: l.timezone || laTimezone,
              enableCache: true,
            }).then(slotTimes => ({
              lId: l.id,
              sId,
              slotTime: getSoonestSlotFromApptSlotBlock(slotTimes),
            }));
          })
          .sequence();
      })
      .sequence()
      .then(locationsAndSlot => {
        setSlotLocationWithTime(
          locationsAndSlot
            .flat()
            .groupBy("lId")
            .mapValues(slots => slots.groupBy("sId").mapValues(slots => slots[0].slotTime)),
        );
      });
  }, [locationsSorted, router.asPath, selectedRegion]);

  return slotLocationWithTime;
};

/* get first location with soonest specialty in location list
eg (assuming sf's urgentcare slot is sooner):
({locations: [berkeley, sf],specialtyId: UrgentCareId}) => {locationId: [sfLocationId]: time: 1666666} */
export const getSortedSoonestLocationWithAvailableSlotBySpecialty = ({
  locationsWithSlots,
  specialtyId,
}: {
  locationsWithSlots: SoonestSlotTimeByLocationAndSpecialty;
  specialtyId?: SpecialtyId;
}): {
  locationId: string;
  time?: number;
} => {
  if (locationsWithSlots.isOEmpty()) {
    // @ts-expect-error TS2322: Type 'null' is not assignable to type 'string'.
    return {locationId: null};
  }
  return (
    locationsWithSlots
      .toArray()
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      .sortBy(l => l[1][specialtyId])
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      .map(l => ({locationId: l[0], time: l[1][specialtyId]}))[0]
  );
};
